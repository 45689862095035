
export const notifi = {
    NM0001: "赤いボックスを入力してください。",

    NM0010: "代表者情報を入力してください。",
    NM0011: "貸出者情報を入力してください。",
}

export const xordin = ["一人目", "二人目", "三人目", "四人目"];

// 代表的な情報 - thông tin người đại diện
export const xrepre =
{
    xfname: "",
    xlname: "",
    xfhame: "",
    xlhame: "",
    xdxsex: "0",
    xzcode: "",
    xadre1: "",
    xadre2: "",
    xadre3: "",
    xadre4: "",
    xphone: ""
};

// 利用者情報 - thông tin người sử dụng
export const xuseri = {
    0: {
        xuseri: {
            ufolow: false,
            ukfnam: "",
            uklnam: "",
            uhfnam: "",
            uhlnam: "",
            udxsex: 0,
            u_year: "",
            umonth: "",
            u__day: "",
            u__age: "",
            uheigh: "",
            ufsize: "",
            uweigh: "",
            clsify: "",
            usctry: 1,
            usikbt: 1
        },
        xitems: {
            istart: "",
            isampm: 0,
            i__end: "",
            i2ampm: 0,
            i__day: 0,
            isitem: "000000000000",
            isubtl: 0,
            ilevel: 0,
            istanc: 0,
        }
    },
    1: {
        xuseri: {
            ufolow: false,
            ukfnam: "",
            uklnam: "",
            uhfnam: "",
            uhlnam: "",
            udxsex: 0,
            u_year: "",
            umonth: "",
            u__day: "",
            u__age: "",
            uheigh: "",
            ufsize: "",
            uweigh: "",
            clsify: "",
            usctry: 1,
            usikbt: 1
        },
        xitems: {
            istart: "",
            isampm: 0,
            i__end: "",
            i2ampm: 0,
            i__day: 0,
            isitem: "000000000000",
            isubtl: 0,
            ilevel: 0,
            istanc: 0,
        }
    },
    2: {
        xuseri: {
            ufolow: false,
            ukfnam: "",
            uklnam: "",
            uhfnam: "",
            uhlnam: "",
            udxsex: 0,
            u_year: "",
            umonth: "",
            u__day: "",
            u__age: "",
            uheigh: "",
            ufsize: "",
            uweigh: "",
            clsify: "",
            usctry: 1,
            usikbt: 1
        },
        xitems: {
            istart: "",
            isampm: 0,
            i__end: "",
            i2ampm: 0,
            i__day: 0,
            isitem: "000000000000",
            isubtl: 0,
            ilevel: 0,
            istanc: 0,
        }
    },
    3: {
        xuseri: {
            ufolow: false,
            ukfnam: "",
            uklnam: "",
            uhfnam: "",
            uhlnam: "",
            udxsex: 0,
            u_year: "",
            umonth: "",
            u__day: "",
            u__age: "",
            uheigh: "",
            ufsize: "",
            uweigh: "",
            clsify: "",
            usctry: 1,
            usikbt: 1
        },
        xitems: {
            istart: "",
            isampm: 0,
            i__end: "",
            i2ampm: 0,
            i__day: 0,
            isitem: "000000000000",
            isubtl: 0,
            ilevel: 0,
            istanc: 0,
        }
    }
}

// 価格 - PRICE
export const xOpric = {
    // ["ski", "boost", "pole", "board", "boost", "ウエア上", "ウエア下", "ヘルメット", "プロテクター", "ZIPFY", "スノーレーザー", "ソリ", "スノースケート"]
    0.5: [2500,   1750, 1000,  2500,  1750,  2000,  2000,  500,  500, 1500, 2000, 500, 2000],
    1:   [3500,   2500, 1000,  3500,  2500,  3000,  3000,  500,  500, 2000, 3500, 500, 3500],
    1.5: [5500,   4000, 1500,  5500,  4000,  4500,  4500, 1000, 1000,    0,    0,   0,    0],
    2:   [6500,   4500, 1500,  6500,  4500,  5500,  5500, 1000, 1000,    0,    0,   0,    0],
    2.5: [8500,   5500, 2000,  8500,  5500,  7000,  7000, 1500, 1500,    0,    0,   0,    0],
    3:   [9500,   6500, 2000,  9500,  6500,  8000,  8000, 1500, 1500,    0,    0,   0,    0],
    4:   [12000,  8000, 2500, 12000,  8000, 10000, 10000, 2000, 2000,    0,    0,   0,    0],
    5:   [15000,  9000, 3000, 15000,  9000, 11000, 11000, 2500, 2500,    0,    0,   0,    0],
    6:   [17000, 10000, 3500, 17000, 10000, 12000, 12000, 3000, 3000,    0,    0,   0,    0],
    7:   [19000, 11000, 4000, 19000, 11000, 13000, 13000, 3500, 3500,    0,    0,   0,    0],
    8:   [21000, 12000, 4500, 21000, 12000, 14000, 14000, 4000, 4000,    0,    0,   0,    0]
}

export const xKpric = {
    // ["ski", "boost", "pole", "board", "boost", "ウエア上", "ウエア下", "ヘルメット", "プロテクター", "ZIPFY", "スノーレーザー", "ソリ", "スノースケート"]
    0.5: [2000,  1500, 1000,  2000, 1500,  1750,  1750,  500,  500, 1500, 2000, 500, 2000],
    1:   [2500,  1750, 1000,  2500, 1750,  2500,  2500,  500,  500, 2000, 3500, 500, 3500],
    1.5: [4000,  2750, 1500,  4000, 2750,  4000,  4000, 1000, 1000,    0,    0,   0,    0],
    2:   [4500,  3000, 1500,  4500, 3000,  4500,  4500, 1000, 1000,    0,    0,   0,    0],
    2.5: [5500,  3500, 2000,  5500, 3500,  5500,  5500, 1500, 1500,    0,    0,   0,    0],
    3:   [6500,  4250, 2000,  6500, 4250,  6500,  6500, 1500, 1500,    0,    0,   0,    0],
    4:   [8000,  5500, 2500,  8000, 5500,  8000,  8000, 2000, 2000,    0,    0,   0,    0],
    5:   [9000,  6500, 3000,  9000, 6500,  9000,  9000, 2500, 2500,    0,    0,   0,    0],
    6:   [10000, 7500, 3500, 10000, 7500, 10000, 10000, 3000, 3000,    0,    0,   0,    0],
    7:   [11000, 8500, 4000, 11000, 8500, 11000, 11000, 3500, 3500,    0,    0,   0,    0],
    8:   [12000, 9500, 4500, 12000, 9500, 12000, 12000, 4000, 4000,    0,    0,   0,    0]
}

export const xiname =  // レンタル種目名
    //  ski  ,boots,spole,board,boost,jacket,helmet,sled,zipfy,racer,shoe, skat
    ["スキー板のみ", "スキーブーツのみ", "ストック", "スノーボードのみ", "ボードブーツのみ", "ウエア上", "ウエア下", "ヘルメット", "プロテクター", "スノードライブ", "スノースクート", "ソリ", "スノースケート"];
// export const xiname = [ // レンタル種目名
//     //  ski  ,boots,spole,board,boost,jacket,helmet,sled,zipfy,racer,shoe, skat
//     ["スキー板のみ", "スキーブーツのみ", "ストック", "スノーボードのみ", "ボードブーツのみ", "ウエア上", "ウエア下", "ヘルメット", "プロテクター", "スノードライブ", "スノースクート", "ソリ", "スノースケート"],
//     ["スキーセット", 0, 0, "スノーボードセット", 0, "ウェアーセット", 0, "ソリ（１日）", "ZIPFY（１日）", "スノーレーサー（１日）", "スノーシュー + ストック（１日）", 0], // 1日
//     [0, 0, 0, 0, 0, 0, 0, 0, "ZIPFY（4H）", 0, "スノーシュー（4H）", "スノースケート + ストック（4H）"], // 4H
//     [0, 0, 0, 0, 0, 0, 0, 0, 0, "スノーレーサー（2H）", 0, "スノースケート（2H）"], // 2H
//     ["スキーセット", 0, 0, "スノーボードセット", 0, 0, 0, 0, 0, 0, 0, 0], // セット1
//     ["ファンスキーセット", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] // セット2
// ]

// セット分析
export const setpre = {
    2: { // set 2
        isitem: "111000000000", // スキー１場合
        inewse: "200000000000", // スキー
        isname: "スキーセット",
        iOpris: {
            0.5: 3750,
            1: 5000,
            1.5: 8500,
            2: 9500,
            2.5: 12500,
            3: 14000,
            4: 17500,
            5: 20000,
            6: 22500,
            7: 25000,
            8: 27500
        },
        iKpris: {
            0.5: 3000,
            1: 3500,
            1.5: 6000,
            2: 6500,
            2.5: 8500,
            3: 9500,
            4: 11500,
            5: 13000,
            6: 14500,
            7: 16000,
            8: 17500
        }
    },
    3: { // set 3
        isitem: "110000000000", // スキー２場合
        inewse: "300000000000", // スキー
        isname: "スキーセット",
        iOpris: {
            0.5: 3750,
            1: 5000,
            1.5: 8500,
            2: 9500,
            2.5: 12500,
            3: 14000,
            4: 17500,
            5: 20000,
            6: 22500,
            7: 25000,
            8: 27500
        },
        iKpris: {
            0.5: 3000,
            1: 3500,
            1.5: 6000,
            2: 6500,
            2.5: 8500,
            3: 9500,
            4: 11500,
            5: 13000,
            6: 14500,
            7: 16000,
            8: 17500
        }
    },
    4: { // set 4
        isitem: "000110000000", // ボード場合
        inewse: "000400000000", // 
        isname: "スノーボードセット",
        iOpris: {
            0.5: 3750,
            1: 5000,
            1.5: 8500,
            2: 9500,
            2.5: 12500,
            3: 14000,
            4: 17500,
            5: 20000,
            6: 22500,
            7: 25000,
            8: 27500
        },
        iKpris: {
            0.5: 3000,
            1: 3500,
            1.5: 6000,
            2: 6500,
            2.5: 8500,
            3: 9500,
            4: 11500,
            5: 13000,
            6: 14500,
            7: 16000,
            8: 17500
        }
    },
    5: { // set 5
        isitem: "000001100000", // ボード場合
        inewse: "000005000000", // 
        isname: "ウェアーセット",
        iOpris: {
            0.5: 3000,
            1: 4000,
            1.5: 6500,
            2: 7500,
            2.5: 9500,
            3: 11000,
            4: 13000,
            5: 14500,
            6: 16000,
            7: 17500,
            8: 19000
        },
        iKpris: {
            0.5: 2500,
            1: 3000,
            1.5: 5000,
            2: 5500,
            2.5: 7000,
            3: 8000,
            4: 10000,
            5: 11000,
            6: 12000,
            7: 13000,
            8: 14000
        }
    }
}

// サイズの種類
export const xsctry = ["", "JP", "US", "EU"];

// サイズの区分
export const xsikbt = ["", "M", "W", "J", "Y"];

// 靴のサイズ - size dày
export const xmemsi = [
    [
        [25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 29.5, 30, 30.5, 31], // JP
        [7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13], // US
        [39, 40, 40.5, 41, 42, 42.5, 43, 44, 44.5, 45, 45.5, 46, 47] // EU
    ],
    [
        [22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 28, 28.5], // JP
        [5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11], // US
        [36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40, 40.5, 41, 41.5, 42] // EU
    ],
    [
        [20, 20.5, 21, 21.5, 22, 22.5, 22.8, 23, 23.5, 24, 24.5], // JP
        [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6],  // US
        [32, 33, 33.5, 34, 35, 35.5, 36, 36.5, 37, 37.5, 38], // EU
    ],
    [
        [15, 15.5, 16, 16.3, 16.5, 16.8, 17, 17.5, 18, 18.5, 19, 19.5],  // JP
        [8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5],  // US
        [25, 25.5, 26, 26.5, 27, 27.5, 28, 29, 29.5, 30, 31, 31.5]  // EU
    ]
];

// delete
export function fdelif(varnow, vardel) {
    let varori = xuseri;
    let xcount = 0;
    for (const key in varnow) {
        if (key == vardel) {
            continue;
        } else {
            varori[xcount] = varnow[key];
            xcount += 1;
        }
    }
    return varori;
}

// 人数調査 - tìm số người
export function fpreso(xrtinf) {
    let xresul = 0;
    Object.keys(xrtinf).map((key) => {
        let xuseri = { ...xrtinf[key].xuseri};
        xuseri.usctry = 0;
        xuseri.usikbt = 0;
        // 少なくとも 1 つの入力に値があればチェックします - chỉ kiểm tra nếu có ít nhất 1 input có giá trị
        const hasval = Object.values(xuseri).some(value => value !== "" && value !== false && value !== 0);
        if (hasval) {
            xresul += 1;
        }
    });
    return xresul;
}

// 数値を金額表示に変換する
export function fcvmny(xtmint) {
    return String(xtmint).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}
